import { Box, Text } from "@chakra-ui/react";
import { InsightsSelect } from "./InsightsSelect";
import { useActiveImmersions } from "../../hooks/useActiveImmersions";
import { BookOpenIcon } from "@heroicons/react/24/outline";
import { useInsights } from "./InsightsContext";
import { useInsightsImmersions } from "../../hooks/useInsightsImmersions";

export const InsightsIntroScreen = () => {
    const immersions = useInsightsImmersions();
    const { setModuleId } = useInsights();
    return (
        <Box flexGrow={1} px="40px">
            <Box
                bg={"gray.100"}
                borderRadius={"20px"}
                justifyItems={"center"}
                paddingX={"160px"}
                paddingTop="86px"
                paddingBottom={"60px"}
                mt="80px"
            >
                <Text
                    mb={"24px"}
                    color="blue.500"
                    fontSize={"36px"}
                    fontWeight={500}
                >
                    Welcome to your insights tool
                </Text>
                <Text
                    mb={"48px"}
                    fontSize={"24px"}
                    fontWeight={500}
                    color="dark.800"
                >
                    Start by selecting a module
                </Text>
                <Box>
                    <InsightsSelect
                        searchable
                        itemToString={(_) => _?.tactic || ""}
                        mapItemKey={(_) => _.id}
                        items={immersions || []}
                        placeholder="Select Module"
                        onChange={(_) => {
                            if (_) {
                                setModuleId(_.id);
                            }
                        }}
                        width={360}
                        leftHandIcon={<BookOpenIcon height={"18px"} />}
                    />
                </Box>
            </Box>
        </Box>
    );
};
