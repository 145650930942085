import { Box, SkeletonText } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { ArtifactCard } from "./ArtifactCard";
import { useInsights } from "./InsightsContext";
import { InsightsControls } from "./InsightsControls";
import { NotepadCard } from "./NotepadCard";
import { TranscriptCard } from "./TranscriptCard";
import { WhiteboardCard } from "./WhiteboardCard";

const SkeletonCard = () => (
    <ArtifactCard title="Loading..." subtitle="Subtitle" isLoading={true}>
        <SkeletonText noOfLines={10} skeletonHeight={"16px"} />
    </ArtifactCard>
);

export const InsightsContent: React.FC = () => {
    const { data, loading, setIsScrolled, expand, setExpand, showMetadata, setShowMetadata } = useInsights();

    const artifacts = data || [];

    const scrollMargin = 20;
    const containerRef = useRef<HTMLDivElement | null>(null);
    const childRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [currentChildIndex, setCurrentChildIndex] = useState<number | null>(
        null,
    );

    const handleScroll = () => {
        if (!containerRef.current) {
            return;
        }

        const containerTop = containerRef.current.getBoundingClientRect().top;
        const containerBottom =
            containerRef.current.getBoundingClientRect().bottom;

        setIsScrolled(containerRef.current.scrollTop > scrollMargin);

        const visibleChildIndex = childRefs.current.findIndex((child) => {
            if (!child) return false;

            const childTop = child.getBoundingClientRect().top;
            const childBottom = child.getBoundingClientRect().bottom;

            return (
                (childTop >= containerTop && childTop < containerBottom) || // Partially visible at the top
                (childBottom > containerTop &&
                    childBottom <= containerBottom) || // Partially visible at the bottom
                (childTop < containerTop && childBottom > containerBottom) // Fully covering the container
            );
        });

        setCurrentChildIndex(visibleChildIndex >= 0 ? visibleChildIndex : null);
    };

    /**
     * Show/hide metadata changes should recompute scroll positions
     */
    useEffect(() => {
        handleScroll();
    }, [showMetadata, expand]);

    /**
     * Create scroll listener when artifact list changes
     * This also ensures that the scroll listener is created when the component mounts
     */
    useEffect(() => {
        const container = containerRef.current;

        if (container) {
            container.addEventListener("scroll", handleScroll);
            handleScroll();
        }

        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll);
            }
        };
    }, [artifacts.length]);

    /**
     * Scroll to the designated index
     * @param index {number}
     */
    const scrollToChild = (index: number) => {
        if (containerRef.current && childRefs.current[index]) {
            const containerTop =
                containerRef.current.getBoundingClientRect().top;
            const childTop =
                childRefs.current[index]!.getBoundingClientRect().top;
            const scrollOffset = childTop - containerTop - scrollMargin;

            containerRef.current.scrollTo({
                top: containerRef.current.scrollTop + scrollOffset,
                behavior: "smooth",
            });
        }
    };

    if (loading) {
        return (
            <Box
                paddingX="40px"
                paddingTop="20px"
                overflowY={"auto"}
                height="100%"
            >
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
            </Box>
        );
    }
    return (
        <Box
            paddingX="40px"
            paddingTop="20px"
            overflowY={"auto"}
            height="100%"
            ref={containerRef}
            pb={"320px"}
            sx={{
                '@media print': {
                    'overflow-y': 'visible'
                }
            }}
        >
            {artifacts.map((artifact, index) => {
                if (artifact.type === "TRANSCRIPTION" && artifact.transcript) {
                    return (
                        <Box
                            key={index.toString()}
                            ref={(el) => (childRefs.current[index] = el)}
                            sx={{ 
                                '@media print': {
                                    'page-break-before': 'always'
                                }
                            }}
                        >
                            <TranscriptCard
                                showMetadata={showMetadata}
                                expanded={expand}
                                transcript={artifact.transcript}
                                artifact={artifact}
                            />
                        </Box>
                    );
                } else if (artifact.type === "ETHERPAD") {
                    return (
                        <Box
                            key={index.toString()}
                            ref={(el) => (childRefs.current[index] = el)}
                            sx={{ 
                                '@media print': {
                                    'page-break-before': 'always'
                                }
                            }}
                        >
                            <NotepadCard
                                showMetadata={showMetadata}
                                expanded={expand}
                                artifact={artifact}
                                etherpad={artifact.etherpad!}
                            />
                        </Box>
                    );
                } else if (artifact.type === "EXCALIDRAW") {
                    return (
                        <Box
                            key={index.toString()}
                            ref={(el) => (childRefs.current[index] = el)}
                            sx={{ 
                                '@media print': {
                                    'page-break-before': 'always'
                                }
                            }}
                        >
                            <WhiteboardCard
                                showMetadata={showMetadata}
                                expanded={expand}
                                artifact={artifact}
                                excalidraw={artifact.excalidraw!}
                            />
                        </Box>
                    );
                }
                return null;
            })}
            <InsightsControls
                shouldShowMetadata={!showMetadata}
                onNext={() => {
                    if (currentChildIndex !== null) {
                        scrollToChild(currentChildIndex + 1);
                    }
                }}
                onPrev={() => {
                    if (currentChildIndex !== null) {
                        scrollToChild(currentChildIndex - 1);
                    }
                }}
                onToggleMetadata={() => {
                    setShowMetadata(!showMetadata);
                }}
                shouldCollapse={expand}
                onExpandCollapse={() => setExpand(!expand)}
            />
        </Box>
    );
};
