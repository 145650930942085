import { Flex, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { DateTime } from "luxon";

export interface InsightsArtifactMetadataProps {
    immersionName: string;
    immersionId: string;
    participants: Array<{
        name: string;
        userId: string;
    }>;
    date: Date;
    timeslotId: string;
    // Optional fields
    steps?: Array<{
        stepNumber: number;
        title: string;
    }>;
    extractedText?: string[];
    cohort?: {
        id: string;
        name: string;
    };
    timeZone?: string;
}

export const InsightsArtifactMetadata: React.FC<
    InsightsArtifactMetadataProps
> = (props) => {
    return (
        <Flex
            paddingX={"40px"}
            paddingY="24px"
            bg={"blue.50"}
            direction={["column", "row"]}
        >
            <Flex direction={"column"} mr="40px">
                <MetadataEntry
                    title="Module"
                    values={[
                        {
                            value: props.immersionName,
                        },
                    ]}
                />
                <MetadataEntry
                    title="Participants"
                    values={props.participants.map((_) => ({
                        value: _.name,
                        link: `/user/${_.userId}`,
                    }))}
                />
                {props.steps && (
                    <MetadataEntry
                        title={props.steps.length === 1 ? "Step" : "Steps"}
                        values={props.steps.map((_) => ({
                            value: `From Step ${_.stepNumber}: ${_.title}`,
                        }))}
                        entryType="list"
                    />
                )}
                {props.extractedText && (
                    <MetadataEntry
                        title="Extracted text"
                        values={props.extractedText.map((text) => ({
                            value: text,
                        }))}
                        entryType="list"
                    />
                )}
            </Flex>
            <Flex direction={"column"}>
                <MetadataEntry
                    title="Date"
                    values={[
                        {
                            // Date format Nov 15, 2024 at 10:30 AM EST
                            value: DateTime.fromJSDate(props.date)
                                .setZone(props.timeZone || "PST")
                                .toFormat(`LLL dd, yyyy 'at' hh:mm a ZZZZ`),
                        },
                    ]}
                />
                <MetadataEntry
                    title="Session ID"
                    values={[
                        {
                            value: props.timeslotId,
                            link: `/session/${props.timeslotId}`,
                        },
                    ]}
                />
                {props.cohort && (
                    <MetadataEntry
                        title="Cohort"
                        values={[
                            {
                                value: props.cohort.name,
                                link: `/cohort/${props.cohort.id}`,
                            },
                        ]}
                    />
                )}
            </Flex>
        </Flex>
    );
};

const MetadataEntry: React.FC<{
    title: string;
    values: Array<{
        value: string;
        link?: string;
    }>;
    entryType?: "list" | "text";
}> = ({ title, values, entryType }) => {
    const type = entryType || "text";
    return (
        <Flex direction="column" mb="20px">
            <Text
                textTransform={"uppercase"}
                fontSize={"12px"}
                lineHeight={"18px"}
                fontWeight={600}
                color={"gray.400"}
                letterSpacing={"0.6px"}
                mb={1}
            >
                {title}
            </Text>
            {(type === "text" || (type === "list" && values.length === 1)) && (
                <Flex direction="row" flexWrap={"wrap"} gap="0.25rem">
                    {values.map((value, index) => (
                        <Fragment key={index}>
                            <Text
                                fontSize={"13px"}
                                fontWeight={600}
                                color={"gray.600"}
                                mb={1}
                                textDecoration={
                                    value.link ? "underline" : undefined
                                }
                            >
                                {value.link ? (
                                    <NavLink to={value.link}>
                                        {value.value}
                                    </NavLink>
                                ) : (
                                    value.value
                                )}
                                {index < values.length - 1 && <span>, </span>}
                            </Text>
                        </Fragment>
                    ))}
                </Flex>
            )}
            {type === "list" && values.length > 1 && (
                <UnorderedList>
                    {values.map((value, index) => (
                        <ListItem
                            fontSize={"12px"}
                            fontWeight={600}
                            color={"gray.600"}
                            key={index}
                            lineHeight={"20px"}
                        >
                            {value.value}
                        </ListItem>
                    ))}
                </UnorderedList>
            )}
        </Flex>
    );
};
