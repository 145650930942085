import { useGlobalContext } from "../context";
import { useTRPC } from "./useTRPC";
import { useIsSuperAdmin } from "./useUserData";

export const useInsightsImmersions = () => {
    const { organizationId } = useGlobalContext();
    const trpc = useTRPC();
    const isSuperAdmin = useIsSuperAdmin();
    const { data: immersions } = trpc.insights.getImmersionsForInsights.useQuery(
        {
            organizationId: isSuperAdmin && organizationId ? organizationId : undefined,
        }
    );
    return immersions || null;
}