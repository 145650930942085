import React, { PropsWithChildren } from "react";

export interface SidebarContext {
    sidebarCollapsed: boolean;
    toggleSidebar: () => void;

    /**
     * Force a sidebar collapse, if the user hasn't set its value before
     */
    collapse: () => void;

    /**
     * Force a sidebar expansion, if the user hasn't set its value before
     */
    expand: () => void;
}

export const SidebarContext = React.createContext<SidebarContext>(
    null as unknown as SidebarContext,
);

export const SidebarProvider: React.FC<PropsWithChildren> = ({ children }) => {
    /**
     * This value defines the sidebar state
     */
    const [sidebarCollapsed, setSidebarCollapsed] = React.useState(false);

    /**
     * This value is used to store the user's preference for the sidebar state
     */
    const [userSidebarCollapsed, setUserSidebarCollapsed] = React.useState<
        boolean | null
    >(null);

    const context: SidebarContext = {
        sidebarCollapsed,
        toggleSidebar: React.useCallback(() => {
            if (userSidebarCollapsed !== null) {
                setSidebarCollapsed(!userSidebarCollapsed);
                setUserSidebarCollapsed(!userSidebarCollapsed);
            } else {
                setSidebarCollapsed(!sidebarCollapsed);
                setUserSidebarCollapsed(!sidebarCollapsed);
            }
        }, [sidebarCollapsed, userSidebarCollapsed]),
        collapse: React.useCallback(() => {
            if (userSidebarCollapsed !== null) {
                return;
            }
            setSidebarCollapsed(true);
        }, [userSidebarCollapsed]),
        expand: React.useCallback(() => {
            if (userSidebarCollapsed !== null) {
                return;
            }
            setSidebarCollapsed(false);
        }, [userSidebarCollapsed]),
    };
    return (
        <SidebarContext.Provider value={context}>
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebar = () => React.useContext(SidebarContext);

/**
 * Hook that ensures the sidebar is collapsed when the component is mounted
 */
export const useNeedsSidebarCollapsed = () => {
    const context = useSidebar();
    React.useEffect(() => {
        context.collapse();
        return () => context.expand();
    }, []);
};
