import React, {
    PropsWithChildren,
    useContext,
    useEffect,
    useReducer,
    useState,
} from "react";
import { AdminRouterOutput } from "server";
import { useTRPC } from "../../hooks/useTRPC";
import { useSearchParams } from "react-router-dom";
import { useGlobalContext } from "../../context";
import { ExportToAIModal } from "./ExportToAIModal";
import { useDisclosure } from "@chakra-ui/react";
import { ComingSoonModal } from "./ComingSoonModal";
import JSZip from 'jszip';

export type InsightsArtifact =
    AdminRouterOutput["insights"]["findInsights"]["data"][0];
export type InsightsTranscriptArtifact = NonNullable<
    InsightsArtifact["transcript"]
>;
export type InsightsEtherpadArtifact = NonNullable<
    InsightsArtifact["etherpad"]
>;
export type InsightsExcalidrawArtifact = NonNullable<
    InsightsArtifact["excalidraw"]
>;

interface InsightsContextState {
    filters: {
        moduleId?: string;
        date: number;
        cohortId?: string;
        showTranscripts?: boolean;
        showEtherpad?: boolean;
        showExcalidraw?: boolean;
    };

    transcriptCount: number;
    etherpadCount: number;
    excalidrawCount: number;

    isScrolled: boolean;
}

const defaultValue: InsightsContextState = {
    filters: {
        moduleId: undefined,
        date: 1,
        cohortId: undefined,
        showTranscripts: true,
        showEtherpad: true,
        showExcalidraw: true,
    },

    transcriptCount: 0,
    etherpadCount: 0,
    excalidrawCount: 0,

    // Main content scroll status
    isScrolled: false,
};

export interface InsightsContext {
    state: InsightsContextState;

    setModuleId: (moduleId: string) => void;
    setCohortId: (cohortId?: string) => void;
    setDateFilter: (value: number) => void;

    showTranscripts: (value: boolean) => void;
    showEtherpad: (value: boolean) => void;
    showExcalidraw: (value: boolean) => void;
    setIsScrolled: (value: boolean) => void;
    expandContent: () => void;
    expand: boolean; 
    setExpand: (value: boolean) => void;
    showMetadata: boolean;
    setShowMetadata: (value: boolean) => void;

    downloadJSONForAI: () => Promise<void>;
    openDownloadModal: () => void;
    openComingSoonModal: () => void;

    data: InsightsArtifact[];
    loading: boolean;
    total: number;
}

type SetIsScrolled = {
    type: "SetIsScrolled";
    value: boolean;
};

type SetModuleId = {
    type: "SetModuleId";
    value?: string;
};

type SetCohortId = {
    type: "SetCohortId";
    value?: string;
};

type SetShowTranscripts = {
    type: "SetShowTranscripts";
    value: boolean;
};

type SetShowEtherpad = {
    type: "SetShowEtherpad";
    value: boolean;
};

type SetShowExcalidraw = {
    type: "SetShowExcalidraw";
    value: boolean;
};

type SetDateFilter = {
    type: "SetDateFilter";
    value: number;
};

type InsightContextAction =
    | SetModuleId
    | SetShowTranscripts
    | SetShowEtherpad
    | SetShowExcalidraw
    | SetDateFilter
    | SetIsScrolled
    | SetCohortId;

const reducer = (
    state: InsightsContextState,
    action: InsightContextAction,
): InsightsContextState => {
    switch (action.type) {
        case "SetModuleId": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    moduleId: action.value,
                },
            };
        }
        case "SetShowTranscripts": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    showTranscripts: action.value,
                },
            };
        }
        case "SetShowEtherpad": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    showEtherpad: action.value,
                },
            };
        }
        case "SetShowExcalidraw": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    showExcalidraw: action.value,
                },
            };
        }
        case "SetDateFilter": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    date: action.value,
                },
            };
        }
        case "SetIsScrolled": {
            return {
                ...state,
                isScrolled: action.value,
            };
        }
        case "SetCohortId": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    cohortId: action.value,
                },
            };
        }
        default:
            return state;
    }
};

export const InsightsContext = React.createContext<InsightsContext>(
    null as unknown as InsightsContext,
);

export const InsightsContextProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const exportModal = useDisclosure();
    const comingSoonModal = useDisclosure();
    const { organizationId } = useGlobalContext();
    const [params, setSearchParams] = useSearchParams();

    const [state, dispatch] = useReducer(reducer, defaultValue);

    const [expand, setExpand] = useState(false);
    const [showMetadata, setShowMetadata] = useState(false);

    const trpc = useTRPC();

    const resourceTypes: ("TRANSCRIPTION" | "ETHERPAD" | "EXCALIDRAW")[] = [];
    if (state.filters.showTranscripts) {
        resourceTypes.push("TRANSCRIPTION");
    }
    if (state.filters.showEtherpad) {
        resourceTypes.push("ETHERPAD");
    }
    if (state.filters.showExcalidraw) {
        resourceTypes.push("EXCALIDRAW");
    }

    const { data: artifactsData, isLoading } =
        trpc.insights.findInsights.useInfiniteQuery(
            {
                resourceTypes,
                ageInDays: state.filters.date,
                immersionId: state.filters.moduleId!,
                cohortId: state.filters.cohortId,
                organizationId,
            },
            {
                enabled: !!state.filters.moduleId,
                getNextPageParam: (lastPage) => lastPage.nextCursor,
            },
        );

    const { mutateAsync: buildInsightsJSON } =
        trpc.insights.exportInsightsJSON.useMutation();

    useEffect(() => {
        if (params.get("module_id")) {
            dispatch({ type: "SetModuleId", value: params.get("module_id")! });
        } else {
            dispatch({ type: "SetModuleId", value: undefined });
        }
    }, [params]);

    const data = artifactsData?.pages.flatMap((page) => page.data) || [];
    const aggregates = artifactsData?.pages[0]?.aggregates;

    const context: InsightsContext = {
        state: {
            ...state,
            transcriptCount: aggregates?.transcription || 0,
            etherpadCount: aggregates?.etherpad || 0,
            excalidrawCount: aggregates?.excalidraw || 0,
        },
        setModuleId: (moduleId: string) =>
            setSearchParams({
                module_id: moduleId,
            }),
        setCohortId: (cohortId?: string) =>
            dispatch({ type: "SetCohortId", value: cohortId }),
        showTranscripts: (value: boolean) =>
            dispatch({ type: "SetShowTranscripts", value }),
        showEtherpad: (value: boolean) =>
            dispatch({ type: "SetShowEtherpad", value }),
        showExcalidraw: (value: boolean) =>
            dispatch({ type: "SetShowExcalidraw", value }),
        setDateFilter: (value: number) =>
            dispatch({ type: "SetDateFilter", value }),
        setIsScrolled: (value: boolean) =>
            dispatch({ type: "SetIsScrolled", value }),
        data: data || [],
        loading: isLoading,
        total: aggregates?.total || 0,
        expandContent: () => setExpand(true),
        expand,
        setExpand,
        showMetadata,
        setShowMetadata,

    downloadJSONForAI: async () => {
        const data = await buildInsightsJSON({
            resourceTypes,
            ageInDays: state.filters.date,
            immersionId: state.filters.moduleId!,
            cohortId: state.filters.cohortId,
            organizationId,
        });

        const zip = new JSZip();
        zip.file("data.json", data);

        const schemaMarkdown = `
# Discussion Data Documentation

## Overview
This JSON file contains structured data from educational discussions and collaborative learning sessions. The data captures participant interactions, including chat messages and individual work in notepads.

## Data Structure

The file contains an array of objects, each representing different types of content:

### 1. Discussion Objects
- \`id\`: Unique identifier
- \`type\`: "Discussion"
- \`immersion\`: Course/session title
- \`time\`: Timestamp
- \`discussion\`: Contains:
  - \`id\`: Discussion identifier
  - \`groupId\`: Group identifier
  - \`messages\`: Array of chat messages with:
    - \`message\`: Text content
    - \`timestamp\`: Message timestamp
    - \`participant\`: Speaker name

### 2. Notepad Objects
- \`id\`: Unique identifier
- \`type\`: "Notepad"
- \`immersion\`: Course/session title
- \`time\`: Timestamp
- \`notepad\`: Contains:
  - \`participationType\`: "individual"
  - \`content\`: HTML-formatted instructions and content
  - \`participants\`: Array of participant names

## Technical Notes

- Timestamps are in milliseconds since epoch
- HTML content in notepad objects includes formatting with classes for colors and styles
- All IDs are in UUID format
- The discussion content includes both verbal exchanges and exercise instructions

## Sample Query Structure

To work with this data, you can query based on:
\`\`\`javascript
// Get all messages from a specific participant
data.filter(item => 
  item.type === "Discussion" && 
  item.discussion.messages.some(msg => 
    msg.participant === "participant_name"
  )
)

// Get all notepad entries
data.filter(item => item.type === "Notepad")
\`\`\`
        `
        zip.file("1_SCHEMA.md", schemaMarkdown);

        const responseFormatRequirements = `
## MANDATORY RESPONSE FORMAT

## For the very first response 

If data files are provided but the user did not submit a specific text prompt or question, please provide some summary statistics describing data in the json file, provide a short explanation of the types of insight that can be requested, along with a list of example queries.  The summary should include the name of the module (found in \`data[*].immersion\`), the number of session transcripts, notepad documents, and drawing documents are included, and the number of unique participants. You should also provide a list of 8 example queries that could be used to extract insights from the data. This should include 4 general and 4 module-specific queries.

The general queries should be: 
1. *What were the top insights participants walked away with?*
2. *What did participants find most useful or applicable to their day-to-day?*
3. *What were participants most confused by?*
4. *How did participants find the experience, overall?*

## For all responses 

All responses must:
- Group and summarize by theme rather than by participant
- Include footnotes or links with deep links to source content
- Use boldface and italics to highlight key points (if supported)
- Format citations with numbers (e.g. [1])
- Include a references list at end if using footnotes (alternatively, format citations as clicable hyperlinks, if supported)
- Use full names for all participants
- Reference discussion comments by timestamp (e.g. "8:53am PST") 
- Reference drawings by title (e.g. "Figure 1")

### Citation URL Patterns
When citing sources, use these link titles and URLs:
- For discussion comments: URL pattern \`${window.location.href}#resource=<resource_id>&timestamp=<timestamp>\` and link title  \`<participant_name> @ <message_timestamp formatted as hh:mmtt>\`
- For notepads: URL pattern \`${window.location.href}#resource=<resource_id>&encoded_text_fragment=<encoded_text_fragment>\` and link title \`Notepad: <resource_name>\`
- For drawings: URL pattern \`${window.location.href}#resource=<resource_id>\` and link title \`Drawing: <resource_name>\`
        `
        zip.file("0_MANDATORY_RESPONSE_FORMAT_REQUIREMENTS.md", responseFormatRequirements);

        const blob = await zip.generateAsync({type: "blob"});
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = "insights-data.zip";

        a.click();

        URL.revokeObjectURL(a.href);
    },
        openDownloadModal: exportModal.onOpen,
        openComingSoonModal: comingSoonModal.onOpen,
    };
    return (
        <InsightsContext.Provider value={context}>
            {children}
            <ExportToAIModal
                isOpen={exportModal.isOpen}
                onClose={exportModal.onClose}
            />
            <ComingSoonModal
                isOpen={comingSoonModal.isOpen}
                onClose={comingSoonModal.onClose}
            />
        </InsightsContext.Provider>
    );
};

export const useInsights = () => {
    const context = useContext(InsightsContext);
    if (!context) {
        throw new Error('useInsights must be used within InsightsContextProvider');
    }
    return context;
}