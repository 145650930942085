import { AdminRouterOutput } from "server";
import { useGlobalContext } from "../context";
import { useTRPC } from "./useTRPC";

type Cohort = AdminRouterOutput["cohorts"]["getCohorts"][0];

export const useActiveCohorts = (): Cohort[] | null => {
    const context = useGlobalContext();
    const trpc = useTRPC();

    const { data: cohorts } = trpc.cohorts.getCohorts.useQuery(
        {
            organizationId: context.organizationId || "",
        },
        { enabled: !!context.organizationId },
    );
    return cohorts || null;
};
