import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Context } from "../context";
import { DELETE, fetchWithAccessToken, GET, POST, PUT } from "../utils";
import Router from "./Router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpLink } from "@trpc/client";
import { trpc } from "../hooks/useTRPC";
import LoadingPage from "../components/common/LoadingPage";
import { ChatwootContextProvider } from "../contexts/ChatwootContext";
import { SidebarProvider } from "../context/SidebarContext";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const App = () => {
    const {
        isLoading,
        isAuthenticated,
        error,
        user,
        loginWithRedirect,
        logout,
        getAccessTokenSilently,
    } = useAuth0();
    const [accessToken, setAccessToken] = useState("");
    const [organizationId, setOrganizationId] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [organizationTimezone, setRawOrganizationTimezone] = useState("");
    const [timezoneName, setTimezoneName] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone,
    );
    const [timezoneSource, setTimezoneSource] = useState<
        "Account" | "UTC" | "Local"
    >("Local");
    const [email, setEmail] = useState(user?.email);
    useEffect(() => {
        setEmail(user?.email);
    }, [user?.email]);
    const setOrganizationTimezone = (timezone: string) => {
        setRawOrganizationTimezone(timezone);
        if (timezoneSource === "Account") setTimezoneName(timezone);
    };
    const [maintenanceWarningDismissed, setMaintenanceWarningDismissed] =
        useState(false);

    let content = <LoadingPage />;

    const trpcClient = trpc.createClient({
        links: [
            httpLink({
                url: process.env.REACT_APP_API_ROOT! + "/trpc/admin",
                fetch(url, options) {
                    return fetch(url, {
                        ...options,
                        credentials: "include",
                        headers: {
                            ...options?.headers,
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                },
                headers() {
                    return {
                        Authorization: `Bearer ${accessToken}`,
                    };
                },
            }),
        ],
    });

    if (error) {
        content = (
            <div>
                {error.name} {error.message}
            </div>
        );
    } else if (isLoading) {
    } else if (!accessToken) {
        getAccessTokenSilently({
            authorizationParams: {
                audience: "server",
                timeoutInSeconds: 1,
            },
        })
            .then((token: string) => {
                // We no longer use session storage for the token, so remove previously set tokens
                sessionStorage.removeItem("auth0Token");
                setAccessToken(token);
            })
            .catch((_: any) => {
                loginWithRedirect({
                    appState: { returnTo: window.location.pathname },
                });
            });
    } else if (!isAuthenticated) {
        loginWithRedirect({
            appState: { returnTo: window.location.pathname },
        });
    } else if (isAuthenticated && user && accessToken) {
        return (
            <Context.Provider
                value={{
                    get: fetchWithAccessToken(accessToken, GET),
                    post: fetchWithAccessToken(accessToken, POST),
                    put: fetchWithAccessToken(accessToken, PUT),
                    delete: fetchWithAccessToken(accessToken, DELETE),
                    email,
                    setEmail,
                    organizationId,
                    organizationName,
                    organizationTimezone,
                    setOrganizationId,
                    setOrganizationName,
                    setOrganizationTimezone,
                    timezoneName,
                    timezoneSource,
                    setTimezoneName,
                    setTimezoneSource,
                    maintenanceWarningDismissed,
                    setMaintenanceWarningDismissed,
                }}
            >
                <QueryClientProvider client={queryClient}>
                    <trpc.Provider
                        client={trpcClient}
                        queryClient={queryClient}
                    >
                        <SidebarProvider>
                            <ChatwootContextProvider>
                                <Router
                                    user={user}
                                    logout={() =>
                                        logout({
                                            logoutParams: {
                                                returnTo:
                                                    window.location.origin +
                                                    "/logout",
                                            },
                                        })
                                    }
                                />
                            </ChatwootContextProvider>
                        </SidebarProvider>
                    </trpc.Provider>
                </QueryClientProvider>
            </Context.Provider>
        );
    }

    return content;
};

export default App;
