import { Box, Flex, Text } from "@chakra-ui/react";
import { useInsights } from "./InsightsContext";

export const InsightsHeader: React.FC = () => {
    const { total, state } = useInsights();
    const label = (() => {
        if (total === 0) {
            return "No Resources";
        } else if (total === 1) {
            return "1 Resource";
        } else {
            return `${total} Resources`;
        }
    })();
    const isMac = navigator.userAgent.toLowerCase().indexOf("mac") >= 0;
    const findShortcut = isMac ? "Command-F" : "Control-F";
    return (
        <Flex
            direction={["column", "row"]}
            paddingX={"24px"}
            paddingTop={"24px"}
            paddingBottom="16px"
            borderBottom="1px solid"
            borderColor={"gray.100"}
            width={"100%"}
            justifyContent={"space-between"}
            bg={state.isScrolled ? "white" : undefined}
            transition={"background-color 0.2s ease-in-out"}
        >
            <Box mb={[4, 0]}>
                <Text fontSize={"18px"} color={"gray.900"} fontWeight={600}>
                    {label}
                </Text>
                <Text
                    display={["none", "initial"]}
                    fontSize={"14px"}
                    color={"gray.400"}
                    fontWeight={400}
                >
                    use {findShortcut} to find specific word matches
                </Text>
            </Box>
        </Flex>
    );
};
