import { Box, Flex, Icon, Skeleton, Text } from "@chakra-ui/react";
import { ReactComponent as GroupOutline } from "design/assets/GroupOutline.svg";
import { ReactComponent as SoloOutline } from "design/assets/SoloOutline.svg";
import { forwardRef, PropsWithChildren } from "react";
import { Transition } from "@headlessui/react";

export interface ArtifactCardProps {
    showMetadata?: boolean;
    expanded?: boolean;
}

export const ArtifactCard = forwardRef<
    HTMLDivElement,
    PropsWithChildren<{
        icon?: React.ReactElement;
        title: string;
        subtitle?: string;
        groupType?: "shared" | "individual";
        showMetadata?: boolean;
        expanded?: boolean;
        metadata?: React.ReactElement;
        isLoading?: boolean;
    }>
>((props, ref) => {
    const {
        children,
        icon,
        title,
        subtitle,
        groupType,
        showMetadata,
        metadata,
        isLoading,
        expanded,
    } = props;
    return (
        <Box
            bg={"white"}
            borderRadius={"12px"}
            border={"1px solid"}
            borderColor={"gray.50"}
            mb="24px"
        >
            <Box
                paddingX="24px"
                paddingY="16px"
                borderBottom={"1px solid"}
                borderColor={"gray.50"}
            >
                <Flex direction="row" justifyContent={"space-between"}>
                    <Flex direction={["column", "row"]}>
                        <Skeleton isLoaded={!isLoading}>
                            <Text
                                fontSize={"18px"}
                                fontWeight={600}
                                display={"inline"}
                            >
                                {icon}
                                {title}
                            </Text>
                        </Skeleton>
                        {subtitle && (
                            <Box
                                borderLeft={"1px solid"}
                                borderColor={"gray.300"}
                                width={0}
                                height={"30px"}
                                display={["none", "inline-block"]}
                                mb={"-8px"}
                                marginX={"0.5rem"}
                            />
                        )}
                        <Skeleton isLoaded={!isLoading} mt="2px">
                            {subtitle && (
                                <Text
                                    fontWeight={400}
                                    color={"gray.900"}
                                    display={"inline"}
                                >
                                    {subtitle}
                                </Text>
                            )}
                        </Skeleton>
                    </Flex>
                    <Flex>
                        {groupType && (
                            <Box
                                bg={"gray.50"}
                                padding={"8px"}
                                border="1px solid"
                                borderColor="gray.100"
                                borderRadius={"20px"}
                                height={"37px"}
                            >
                                <Text
                                    color="gray.900"
                                    fontSize="13px"
                                    fontWeight={400}
                                >
                                    {groupType === "individual" && (
                                        <Icon
                                            as={SoloOutline}
                                            height="18px"
                                            width="18px"
                                            mr={"0.25rem"}
                                        />
                                    )}
                                    {groupType === "shared" && (
                                        <Icon
                                            as={GroupOutline}
                                            height="18px"
                                            width="18px"
                                            mr={"0.25rem"}
                                        />
                                    )}
                                    {groupType === "individual" && "Individual"}
                                    {groupType === "shared" && "Shared"}
                                </Text>
                            </Box>
                        )}
                    </Flex>
                </Flex>
            </Box>
            <Transition.Root show={showMetadata || false}>
                <Transition.Child
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    {metadata}
                </Transition.Child>
            </Transition.Root>
            <Box
                maxH={expanded ? undefined : "320px"}
                overflowY={"auto"}
                ref={ref}
                position={"relative"}
            >
                {children}
            </Box>
        </Box>
    );
});
