import { Box, Text } from "@chakra-ui/react";

export const InsightsEmptyState = () => {
    return (
        <Box flexGrow={1} px="40px">
            <Box
                bg={"gray.100"}
                borderRadius={"20px"}
                justifyItems={"center"}
                paddingX={"160px"}
                paddingTop="86px"
                paddingBottom={"60px"}
                mt="80px"
                py="170px"
            >
                <Text
                    mb={"14px"}
                    color="dark.500"
                    fontSize={"24px"}
                    fontWeight={500}
                >
                    No matching resources
                </Text>
                <Text fontSize={"18px"} fontWeight={400} color="dark.500">
                    Please adjust the filters on the left
                </Text>
            </Box>
        </Box>
    );
};
