import { NavLink } from "react-router-dom";
import { NavigationItemData } from "./navigation";
import React, { Fragment } from "react";
import classNames from "classnames";
import { Transition } from "@headlessui/react";
import { Badge } from "@chakra-ui/react";

export const NavItem: React.FC<{
    item: NavigationItemData;
    collapsed?: boolean;
}> = ({ item, collapsed }) => {
    return (
        <div>
            {item.link ? (
                <NavLink
                    to={item.link}
                    className={({ isActive }) =>
                        classNames(
                            "group flex items-center px-2 py-2",
                            "text-sm font-normal rounded-md",
                            isActive
                                ? "text-blue-600 font-semibold"
                                : "text-dark-800",
                            item.link
                                ? "hover:bg-blue-50 hover:text-blue-600"
                                : "text-dark-800 cursor-default",
                        )
                    }
                    style={{ height: 40 }}
                >
                    {item.icon && (
                        <item.icon
                            className={classNames(
                                "flex-shrink-0 stroke-1 group-hover:text-blue-600 mr-2 h-6 w-6",
                            )}
                            aria-hidden="true"
                        />
                    )}
                    <Transition.Root show={!collapsed} as={Fragment}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <p style={{ whiteSpace: "nowrap" }}>
                                {item.name}{" "}
                                {item.isBeta ? (
                                    <Badge
                                        variant="solid"
                                        fontSize={9}
                                        colorScheme="blue"
                                        textTransform={"capitalize"}
                                        mt={-4}
                                        lineHeight={2}
                                    >
                                        Beta
                                    </Badge>
                                ) : null}
                            </p>
                        </Transition.Child>
                    </Transition.Root>
                </NavLink>
            ) : (
                <p
                    className={classNames(
                        "group flex items-center px-2 py-2",
                        "text-sm font-normal rounded-md",
                        "text-blue-600 font-semibold",
                        "text-dark-800 cursor-default",
                    )}
                    style={{
                        minHeight: 40,
                    }}
                >
                    {item.icon && (
                        <item.icon
                            className={classNames(
                                "flex-shrink-0 stroke-1 group-hover:text-blue-600 mr-2 h-6 w-6",
                            )}
                            aria-hidden="true"
                        />
                    )}
                    <Transition.Root show={!collapsed} as={Fragment}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <p style={{ whiteSpace: "nowrap" }}>{item.name}</p>
                        </Transition.Child>
                    </Transition.Root>
                </p>
            )}
            {item.spacer ? (
                <>
                    <div className="pt-2 border-b-2" />
                    <div className="pt-2" />
                </>
            ) : null}
        </div>
    );
};
