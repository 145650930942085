import {
    Button,
    Flex,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from "@chakra-ui/react";
import { ArrowDownTrayIcon, PrinterIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useInsights } from "./InsightsContext";

export const ExportToAIModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
}> = ({ isOpen, onClose }) => {
    const {downloadJSONForAI, expandContent, setShowMetadata} = useInsights()
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent minW={["auto", "600px"]} w={["80%"]} p={"20px"}>
                <ModalHeader>
                    <Heading
                        fontWeight="bold"
                        fontSize="22px"
                    >
                        Export
                    </Heading>
                </ModalHeader>
                <ModalBody mt={"4px"}>
                    <Text lineHeight={"22px"} mb={'22px'} fontWeight={400} fontSize={'14px'}>
                        This data export option is designed to help you explore
                        your Sparkwise data using the AI tools or services
                        already available within your organization.
                    </Text>
                    <Text lineHeight={"22px"} mb={'22px'} fontWeight={400} fontSize={'14px'}>
                        The data is exported into a ".zip" file containing
                        the responses that you just previewed along with a set of guidance to your AI to enhance inquiry.
                    </Text>
                    <Text lineHeight={"22px"} mb={'22px'} fontWeight={400} fontSize={'14px'}>
                        <strong>After downloading the zip file, open it and upload its contents to your AI tool. Submit (without comment) to get started.</strong>
                    </Text>
                </ModalBody>
                <ModalFooter pb={0}>
                    <Flex
                        w={"100%"}
                        justifyContent={"flex-end"}
                        direction={"row"}
                        gap={"16px"}
                    >
                        <Button
                            onClick={onClose}
                            variant="outline"
                            colorScheme="gray"
                            background="white"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="outline"
                            colorScheme="gray"
                            background="white"
                            onClick={async () => {
                                onClose();
                                expandContent();
                                setShowMetadata(true); 
                                setTimeout(window.print, 250);
                            }}
                        >
                            <PrinterIcon height={"20px"} />
                            <Text ml={2}>Print/PDF</Text>
                        </Button>
                        <Button
                            onClick={async () => {
                                await downloadJSONForAI();
                                onClose();
                            }}
                            variant="solid"
                            colorScheme="blue"
                        >
                            <ArrowDownTrayIcon height={"20px"} />
                            <Text ml={2}>Export for AI</Text>
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
