import { background, Box, Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useOrganization } from "../hooks/useOrganization";
import {
    InsightsContextProvider,
    useInsights,
} from "./Insights/InsightsContext";
import { InsightsEmptyState } from "./Insights/InsightsEmptyState";
import { InsightsMain } from "./Insights/InsightsMain";
import { InsightsSidebar } from "./Insights/InsightsSidebar";
import { InsightsIntroScreen } from "./Insights/InsightsIntroScreen";
import { useChatwoot } from "../contexts/ChatwootContext";
import { useNeedsSidebarCollapsed } from "../context/SidebarContext";

export const Insights: React.FC = () => {
    const params = useParams();
    useOrganization(params.organizationId || "");
    const { show, hide } = useChatwoot();

    useEffect(() => {
        hide();
        return () => {
            show();
        };
    }, []);

    return (
        <InsightsContextProvider>
            <InsightsInner />
        </InsightsContextProvider>
    );
};

const InsightsInner = () => {
    useNeedsSidebarCollapsed();
    const { state, data } = useInsights();
    const isFilterSet = !!state.filters.moduleId;
    const hasData = data?.length > 0;
    const showIntroScreen = !isFilterSet;
    const showEmptyState = isFilterSet && !hasData;
    const showMain = isFilterSet && hasData;
    return (
        <Box pos={"absolute"} top={0} left={0} width={"100%"} height={"100%"}>
            <Flex
                p={0}
                grow={1}
                direction={["column", "row"]}
                height={"inherit"}
            >
                <InsightsSidebar />
                <Flex
                    bg="gray.50"
                    p={0}
                    justifyContent={"center"}
                    grow={1}
                    alignItems={"flex-start"}
                    sx={{
                        '@media print': {   
                            'background': "white",
                        }
                    }}
                >
                    {showIntroScreen && <InsightsIntroScreen />}
                    {showEmptyState && <InsightsEmptyState />}
                    {showMain && <InsightsMain />}
                </Flex>
            </Flex>
        </Box>
    );
};
