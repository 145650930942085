import { Box, BoxProps, Flex, Text } from "@chakra-ui/react";
import {
    ArrowDownTrayIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    CodeBracketIcon,
    SparklesIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useInsights } from "./InsightsContext";
import { ReactComponent as UpDownArrow } from "design/assets/UpDownArrow.svg";

const ControlButton: React.FC<
    {
        icon: React.ReactElement;
        label?: string;
        onClick?: () => void;
        separator?: boolean;
        showOnMobile?: boolean;
        showLabelOnMobile?: boolean;
        variant?: "default" | "gradient";
    } & BoxProps
> = (props) => {
    const {
        icon,
        label,
        onClick,
        separator,
        showOnMobile,
        variant,
        showLabelOnMobile,
    } = props;
    return (
        <Flex display={showOnMobile ? "flex" : ["none", "flex"]}>
            <Box py="10px" {...props}>
                {variant === "gradient" ? (
                    <GradientButton
                        onClick={onClick}
                        icon={icon}
                        label={label}
                        showLabelOnMobile={showLabelOnMobile}
                    />
                ) : (
                    <DefaultButton
                        onClick={onClick}
                        icon={icon}
                        label={label}
                        showLabelOnMobile={showLabelOnMobile}
                    />
                )}
            </Box>
            {separator && (
                <Box
                    borderColor={"gray.100"}
                    borderRightStyle={"solid"}
                    borderRightWidth={"1px"}
                    height="100%"
                />
            )}
        </Flex>
    );
};

const DefaultButton: React.FC<{
    onClick?: () => void;
    icon?: React.ReactElement;
    label?: string;
    showLabelOnMobile?: boolean;
}> = ({ onClick, icon, label, showLabelOnMobile }) => {
    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            p="8px"
            cursor={"pointer"}
            _hover={{
                bg: "gray.50",
            }}
            onClick={onClick}
            borderRadius={"8px"}
        >
            {icon}
            {label ? (
                <Text
                    display={showLabelOnMobile ? undefined : ["none", "block"]}
                    ml={"8px"}
                >
                    {label}
                </Text>
            ) : null}
        </Box>
    );
};

const GradientButton: React.FC<{
    onClick?: () => void;
    icon?: React.ReactElement;
    label?: string;
    showLabelOnMobile?: boolean;
}> = ({ onClick, icon, label, showLabelOnMobile }) => {
    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            p="7px"
            cursor={"pointer"}
            _hover={{
                bg: "linear-gradient(79deg, #E2DDFA 19.7%, #D3E5FF 81.59%)",
            }}
            onClick={onClick}
            borderRadius={"8px"}
            borderColor={"#A798F1"}
            borderWidth={"1px"}
            borderStyle={"solid"}
            bg={"linear-gradient(79deg, #F0EEFD 19.7%, #EDF5FF 81.59%)"}
        >
            {icon}
            {label ? (
                <Text
                    display={showLabelOnMobile ? undefined : ["none", "block"]}
                    bg={"linear-gradient(79deg, #6D54E8 19.7%, #1A6CE5 81.59%)"}
                    backgroundClip={"text"}
                    ml={"8px"}
                >
                    {label}
                </Text>
            ) : null}
        </Box>
    );
};

export const InsightsControls: React.FC<{
    shouldShowMetadata?: boolean;
    onNext?: () => void;
    onPrev?: () => void;
    onToggleMetadata?: () => void;
    shouldCollapse?: boolean;
    onExpandCollapse?: () => void;
}> = ({
    onNext,
    onPrev,
    onToggleMetadata,
    shouldShowMetadata,
    shouldCollapse,
    onExpandCollapse,
}) => {
    const { openDownloadModal, openComingSoonModal } = useInsights();
    return (
        <Flex
            display={["none", "flex"]}
            position={["fixed", "absolute"]}
            bottom="20px"
            right="20px"
            bg="white"
            borderRadius={"8px"}
            boxShadow={"0px 4px 16px 0px rgba(0, 0, 0, 0.12)"}
            overflow={"hidden"}
            sx={{
                '@media print': {
                    display: 'none'
                }
            }}
        >
            <ControlButton
                icon={<ChevronUpIcon height={"20px"} />}
                onClick={onPrev}
                pl={"10px"}
                pr={"6px"}
            />
            <ControlButton
                icon={<ChevronDownIcon height={"20px"} />}
                onClick={onNext}
                separator
                pl={"6px"}
                pr={"12px"}
            />
            <ControlButton
                icon={<CodeBracketIcon height={"20px"} />}
                label={shouldShowMetadata ? "Show Metadata" : "Hide Metadata"}
                onClick={onToggleMetadata}
                showOnMobile
                pl={"10px"}
                pr={"6px"}
            />
            <ControlButton
                icon={<UpDownArrow height={"20px"} />}
                label={shouldCollapse ? "Collapse" : "Expand"}
                onClick={onExpandCollapse}
                separator
                showOnMobile
                pl={"6px"}
                pr={"10px"}
            />
            <ControlButton
                icon={<ArrowDownTrayIcon height={"20px"} />}
                label={"Export"}
                onClick={openDownloadModal}
                showOnMobile
                pl={"10px"}
                pr={"6px"}
            />
            <ControlButton
                icon={<SparklesIcon height={"20px"} color={"#6D54E8"} />}
                label={"Ask Sparkwise AI"}
                onClick={openComingSoonModal}
                variant={"gradient"}
                showOnMobile
                showLabelOnMobile
                pl={"6px"}
                pr={"10px"}
            />
        </Flex>
    );
};
