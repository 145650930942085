import { Flex } from "@chakra-ui/react";
import { InsightsHeader } from "./InsightsHeader";
import { InsightsContent } from "./InsightsContent";

export const InsightsMain: React.FC = () => {
    return (
        <Flex
            width="100%"
            direction={"column"}
            position="relative"
            height={"100%"}
        >
            <InsightsHeader />
            <InsightsContent />
        </Flex>
    );
};
