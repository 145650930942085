import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { trpc } from "../../hooks/useTRPC";
import { Flex } from "@chakra-ui/react";
import { useNeedsSidebarCollapsed } from "../../context/SidebarContext";

export const Etherpad = () => {
    useNeedsSidebarCollapsed();
    const { padId } = useParams();

    const { data: etherpadCookie } = trpc.learningDesign.getPadSession.useQuery(
        { padId: padId! },
    );
    // Unique is used to force a reload of the iframe.
    const url = `https://pad.sparkwise.co/p/${padId}?showControls=true&showChat=false&showLineNumbers=true&useMonospaceFont=false&userName=Admin&theme=default&unique=${etherpadCookie}`;

    useEffect(() => {
        // Cookie which expires in 1 day.
        document.cookie = `sessionID=${etherpadCookie}; max-age=86400; path=/; Domain=.sparkwise.co`;
    }, [etherpadCookie]);

    return (
        <Flex
            flex="1"
            direction="column"
            borderWidth="1px"
            borderColor="gray.100"
            borderRadius="12px"
            height={"90%"}
            overflow="hidden"
        >
            <iframe
                title={"Sample title"}
                key={padId}
                src={url}
                height="100%"
                width="100%"
            />
        </Flex>
    );
};
