import {
    Button,
    Flex,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from "@chakra-ui/react";
import React from "react";

export const ComingSoonModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
}> = ({ isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent minW={["auto", "600px"]} w={["80%"]} p={"20px"}>
                <ModalHeader>
                    <Heading fontWeight="bold" fontSize="22px">
                        Ask Sparkwise AI
                    </Heading>
                </ModalHeader>
                <ModalBody mt={"4px"}>
                    <Text
                        lineHeight={"22px"}
                        fontWeight={400}
                        fontSize={"14px"}
                    >
                        Coming soon...
                    </Text>
                </ModalBody>
                <ModalFooter pb={0}>
                    <Flex
                        w={"100%"}
                        justifyContent={"flex-end"}
                        direction={"row"}
                        gap={"16px"}
                    >
                        <Button
                            onClick={onClose}
                            variant="outline"
                            colorScheme="gray"
                            background="white"
                        >
                            Close
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
