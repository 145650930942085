import { useEffect } from "react";
import { useGlobalContext } from "../context";
import { useTRPC } from "./useTRPC";
import { AdminRouterOutput } from "server";

type Organization = AdminRouterOutput["org"]["getOrgById"];

export const useOrganization = (
    organizationId: string,
): Organization | null => {
    const context = useGlobalContext();
    const trpc = useTRPC();

    const { data: organization } = trpc.org.getOrgById.useQuery(organizationId);

    useEffect(() => {
        if (organization) {
            context.setOrganizationId(organization.id);
            context.setOrganizationName(organization.name);
            context.setOrganizationTimezone(
                organization.timezone ? organization.timezone.name : "UTC",
            );
        }
    }, [organization, context]);

    return organization || null;
};
