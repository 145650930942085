import { Box, Icon, Image } from "@chakra-ui/react";
import { ReactComponent as Paintbrush } from "design/assets/Paintbrush.svg";
import { ArtifactCard, ArtifactCardProps } from "./ArtifactCard";
import { InsightsArtifactMetadata } from "./InsightsArtifactMetadata";
import {
    InsightsArtifact,
    InsightsExcalidrawArtifact,
} from "./InsightsContext";

export const WhiteboardCard: React.FC<
    ArtifactCardProps & {
        artifact: InsightsArtifact;
        excalidraw: InsightsExcalidrawArtifact;
    }
> = (props) => {
    const { artifact, excalidraw } = props;
    const metadata = {
        immersionName: artifact.immersion.name,
        immersionId: artifact.immersion.id,
        participants: excalidraw.participants || [],
        steps: excalidraw.steps || [],
        date: new Date(artifact.time),
        timeslotId: artifact.timeslotId,
        cohort: artifact.cohort || undefined,
        extractedText: excalidraw.extractedText,
    };

    return (
        <ArtifactCard
            title={"Whiteboard"}
            subtitle={"Practice personifying"}
            icon={<Icon as={Paintbrush} mb={1} mr={1} />}
            groupType={excalidraw.participationType}
            metadata={<InsightsArtifactMetadata {...metadata} />}
            showMetadata={props.showMetadata}
            expanded={props.expanded}
        >
            <Box paddingX="40px" paddingY="24px">
                <Image src={excalidraw.imageUrl} alt="Whiteboard" />
            </Box>
        </ArtifactCard>
    );
};
