import { useParams } from "react-router-dom";
import { trpc } from "../../hooks/useTRPC";
import { Image } from "@chakra-ui/react";
import { useNeedsSidebarCollapsed } from "../../context/SidebarContext";

export const CloudflareImage = () => {
    useNeedsSidebarCollapsed();
    const { systemId } = useParams();

    const { data: signed_url } =
        trpc.learningDesign.getCloudflareImageURL.useQuery({
            systemId: systemId!,
        });

    return (
        <Image
            objectFit="contain"
            borderStyle="solid"
            borderColor="gray.100"
            borderRadius="12px"
            src={signed_url}
        />
    );
};
