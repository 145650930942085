import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { ReactComponent as Transcript } from "design/assets/Transcript.svg";
import { DateTime } from "luxon";
import { useGlobalContext } from "../../context";
import { ArtifactCard, ArtifactCardProps } from "./ArtifactCard";
import { InsightsArtifactMetadata } from "./InsightsArtifactMetadata";
import {
    InsightsArtifact,
    InsightsTranscriptArtifact,
} from "./InsightsContext";
import { useEffect, useRef, useState } from "react";

interface TranscriptItem {
    id: string;
    name: string;
    time: number;
    stepName: string;
    stepNumber: number;
    message: string;
}

export const TranscriptCard: React.FC<
    ArtifactCardProps & {
        transcript: InsightsTranscriptArtifact;
        artifact: InsightsArtifact;
    }
> = (props) => {
    const context = useGlobalContext();
    const tz = context.timezoneName || "UTC";
    const items: TranscriptItem[] = props.transcript.messages.map((message) => {
        return {
            id: message.id,
            name: message.participant.name,
            time: message.timestamp,
            stepName: message.step.name,
            stepNumber: message.step.number,
            message: message.message,
        };
    });
    let prevItem: TranscriptItem | null = null;
    const groupedItems: Array<{
        id: string;
        firstItem: TranscriptItem;
        items: TranscriptItem[];
    }> = items.reduce(
        (
            acc: Array<{
                id: string;
                firstItem: TranscriptItem;
                items: TranscriptItem[];
            }>,
            item: TranscriptItem,
            index: number,
        ) => {
            if (!prevItem) {
                prevItem = item;
                return [
                    {
                        id: item.id,
                        firstItem: item,
                        items: [item],
                    },
                ];
            }
            if (
                prevItem.name === item.name &&
                Math.abs(item.time - prevItem.time) < 15000
            ) {
                prevItem = item;
                const lastEntry = acc[acc.length - 1];
                if (lastEntry) {
                    lastEntry.items.push(item);
                    return acc;
                }
            }
            prevItem = item;
            return [
                ...acc,
                {
                    id: item.id,
                    firstItem: item,
                    items: [item],
                },
            ];
        },
        [],
    );

    const participantIds = Array.from(
        new Set(props.transcript.messages.map((_) => _.participant.id)),
    );
    const participants = participantIds.map((id) => {
        return props.transcript.messages
            .map((_) => _.participant)
            .find((_) => _.id === id)!;
    });
    const metadata = {
        immersionName: props.artifact.immersion.name,
        immersionId: props.artifact.immersion.id,
        participants: participants.map((_) => ({
            userId: _.userId,
            name: _.name,
        })),
        date: new Date(props.artifact.time),
        timeslotId: props.artifact.timeslotId,
    };
    const containerRef = useRef<HTMLDivElement | null>(null);
    const childRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [currentChildIndex, setCurrentChildIndex] = useState<number | null>(
        null,
    );
    const scrolledItem =
        currentChildIndex !== null ? groupedItems[currentChildIndex] : null;

    const handleScroll = () => {
        if (!containerRef.current) {
            return;
        }

        const containerTop =
            containerRef.current.getBoundingClientRect().top + 40;
        const containerBottom =
            containerRef.current.getBoundingClientRect().bottom;

        const visibleChildIndex = childRefs.current.findIndex((child) => {
            if (!child) return false;

            const childTop = child.getBoundingClientRect().top;
            const childBottom = child.getBoundingClientRect().bottom;

            return (
                (childTop >= containerTop && childTop < containerBottom) || // Partially visible at the top
                (childBottom > containerTop &&
                    childBottom <= containerBottom) || // Partially visible at the bottom
                (childTop < containerTop && childBottom > containerBottom) // Fully covering the container
            );
        });

        setCurrentChildIndex(visibleChildIndex >= 0 ? visibleChildIndex : null);
    };
    useEffect(() => {
        const container = containerRef.current;

        if (container) {
            container.addEventListener("scroll", handleScroll);
            handleScroll();
        }

        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll);
            }
        };
    }, [items]);
    return (
        <ArtifactCard
            title="Discussion"
            subtitle={props.artifact.immersion.name}
            icon={<Icon as={Transcript} mb={1} mr={1} />}
            metadata={<InsightsArtifactMetadata {...metadata} />}
            showMetadata={props.showMetadata}
            expanded={props.expanded}
            ref={containerRef}
        >
            {scrolledItem && !props.expanded && (
                <Box
                    position={"sticky"}
                    bg={"white"}
                    w={"100%"}
                    height={"40px"}
                    px={"24px"}
                    top={0}
                >
                    <Box
                        top={"22px"}
                        position={"relative"}
                        w={"100%"}
                        borderTop={"1px solid"}
                        borderColor={"blue.200"}
                    />
                    <Box
                        py={"2px"}
                        px={"6px"}
                        bg={"blue.50"}
                        borderRadius={"12px"}
                        border={"1px solid"}
                        borderColor={"blue.200"}
                        display={"inline-block"}
                        position={"absolute"}
                        top={"10px"}
                    >
                        <Text color={"blue.500"} fontSize={"12px"}>
                            <b>Step {scrolledItem.firstItem.stepNumber}:</b>{" "}
                            {scrolledItem.firstItem.stepName}
                        </Text>
                    </Box>
                </Box>
            )}
            <Box paddingX="24px" paddingY="16px">
                {groupedItems.map((item, index) => {
                    return (
                        <Box
                            ref={(el) => (childRefs.current[index] = el)}
                            mb="24px"
                            key={index.toString()}
                        >
                            <Flex
                                mb={4}
                                alignItems={"flex-start"}
                                direction={["column", "row"]}
                            >
                                <Flex
                                    mr={"16px"}
                                    w={"220px"}
                                    justifyContent={["flex-start", "flex-end"]}
                                    alignItems={"flex-start"}
                                    mb={[2, 0]}
                                >
                                    <Text
                                        textTransform={"lowercase"}
                                        mr={2}
                                        fontSize={"12px"}
                                        color={"gray.400"}
                                        mt={"2px"}
                                    >
                                        <i>
                                            {DateTime.fromMillis(
                                                item.firstItem.time,
                                            )
                                                .setZone(tz)
                                                .toFormat(`hh:mm a`)}
                                        </i>
                                    </Text>
                                    <Text
                                        fontSize={"14px"}
                                        fontWeight={600}
                                        maxW={"120px"}
                                        mt={props.expanded ? "18px" : undefined}
                                    >
                                        {item.firstItem.name}
                                    </Text>
                                </Flex>
                                <Flex
                                    direction={"column"}
                                    alignContent={"flex-start"}
                                >
                                    {props.expanded && (
                                        <Text
                                            fontSize={"12px"}
                                            color={"gray.400"}
                                        >
                                            <i>{item.firstItem.stepName}</i>
                                        </Text>
                                    )}
                                    {item.items.map((message) => (
                                        <Text
                                            fontSize={"14px"}
                                            fontWeight={400}
                                        >
                                            {message.message}
                                        </Text>
                                    ))}
                                </Flex>
                            </Flex>
                        </Box>
                    );
                })}
            </Box>
        </ArtifactCard>
    );
};
