import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { trpc } from "../../hooks/useTRPC";
import { useNeedsSidebarCollapsed } from "../../context/SidebarContext";

export const DownloadableFile = () => {
    useNeedsSidebarCollapsed();
    const { systemId } = useParams();

    const [fileUrl, setFileUrl] = useState<string | null>(null);

    const { data, isLoading, error } =
        trpc.learningDesign.getDownloadableFile.useQuery(
            { systemId: systemId! },
            { enabled: !!systemId },
        );

    useEffect(() => {
        if (isLoading || !data) return;

        const createFileUrl = () => {
            try {
                const byteCharacters = atob(data.data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: data.contentType });

                const url = URL.createObjectURL(blob);
                setFileUrl(url);
            } catch (error) {
                console.error(
                    "DownloadableFile (for admin dash preview) - Error creating file URL:",
                    error,
                );
            }
        };
        createFileUrl();

        // Cleanup function to revoke the object URL when component unmounts
        return () => {
            if (fileUrl) {
                URL.revokeObjectURL(fileUrl);
            }
        };
    }, [data, isLoading]);

    if (isLoading) {
        return <p>Loading file...</p>;
    }

    if (error) {
        return <p>Error loading file: {error.message}</p>;
    }

    return (
        <div className="w-full h-full">
            {fileUrl ? (
                <iframe src={fileUrl} width="100%" height="100%"></iframe>
            ) : (
                <p>Loading file...</p>
            )}
        </div>
    );
};
