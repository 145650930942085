import {
    Box,
    Button,
    Flex,
    Heading,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    ListItem,
    Text,
    UnorderedList,
} from "@chakra-ui/react";
import CheckIcon from "@heroicons/react/16/solid/CheckIcon";
import { XCircleIcon } from "@heroicons/react/24/outline";
import Downshift from "downshift";
import React, { useMemo } from "react";
import { AdminRouterOutput } from "server/src/trpc";
import { useGlobalContext } from "../../context";
import { useTRPC } from "../../hooks/useTRPC";
import { useIsSuperAdmin } from "../../hooks/useUserData";

type Immersion = AdminRouterOutput["immersions"]["getActiveImmersions"][0];

interface IndexedImmersion {
    immersion: Immersion;
    index: number;
}

interface ImmersionGroup {
    topic: string;
    items: IndexedImmersion[];
}

interface Props {
    showClear?: boolean | "inline";
    disabled?: boolean;
    showSave?: boolean;
    saveDisabled?: boolean;
    saveLabel?: string;
    onSave?: () => void;
    savedImmersionId?: string;
    savedImmersionName?: string;
    savedImmersionTopic?: string;
    savedImmersionTactic?: string;
    selectedImmersionId?: string;
    pending: boolean;
    onChange: (immersion: Immersion | null) => void;
    helpText?: string;
}

const ImmersionSelect = (props: Props) => {
    const { organizationName, organizationId } = useGlobalContext();
    const trpc = useTRPC();
    const isSuperAdmin = useIsSuperAdmin();
    const immersionMap = (
        immersion?: Pick<Immersion, "name" | "topic" | "tactic"> | null,
    ): string => {
        if (!immersion) return "";
        return isSuperAdmin
            ? [immersion.name, immersion.tactic].join(" | ")
            : immersion.tactic || "";
    };
    // Don't reload for 2 mins bc airtable is rate-limited.
    const { data: immersions } = trpc.immersions.getActiveImmersions.useQuery(
        isSuperAdmin && organizationId ? {
            organizationId
        } : undefined
    );
    const selectedImmersion = immersions?.find(
        (immersion) => immersion.id === props.selectedImmersionId,
    );
    const savedImmersion = props.savedImmersionId
        ? immersions?.find(
              (immersion) => immersion.id === props.savedImmersionId,
          )
        : undefined;

    const immersionPredicate = (immersion: Immersion, input: string) => {
        const sanitizedInput = input.trim().toLowerCase();
        return (
            immersionMap(immersion).toLowerCase().includes(sanitizedInput) ||
            immersion.topic.name.toLowerCase().includes(sanitizedInput)
        );
    };

    const filterImmersions = (inputValue: string | null): Immersion[] =>
        (immersions || []).filter(
            (immersion) =>
                // Only show super admins unbuilt immersions.
                (isSuperAdmin || immersion.buildStatus === "Built") &&
                (!inputValue || immersionPredicate(immersion, inputValue)) &&
                immersion.id !== props.savedImmersionId,
        );

    /**
     * Immersions can be grouped by organization or all immersions.
     * @param input {string} Text input we filter the immersions against
     * @param custom {boolean} Whether to filter by custom immersions
     * @param offset {number} Offset to add to the index of the immersions,
     *  so each is indexable regardless of its group
     */
    const groupImmersions = ({
        input,
        custom,
        offset,
    }: {
        input: string | null;
        custom: boolean;
        offset?: number;
    }) =>
        filterImmersions(input)
            .filter((_) => _.custom === custom)
            .reduce((acc, immersion, index) => {
                const theOffset = offset || 0;
                const key = immersion.topic.name;
                const existing = acc.find((group) => group.topic === key);
                if (existing) {
                    existing.items.push({
                        immersion,
                        index: index + theOffset,
                    });
                } else {
                    acc.push({
                        topic: key,
                        items: [
                            {
                                immersion,
                                index: index + theOffset,
                            },
                        ],
                    });
                }
                return acc;
            }, [] as ImmersionGroup[])
            .map((_) => ({
                ..._,
                items: _.items.sort((a, b) =>
                    immersionMap(a.immersion).localeCompare(
                        immersionMap(b.immersion),
                    ),
                ),
            }))
            .sort((a, b) => a.topic.localeCompare(b.topic));

    const placeholder = useMemo(() => {
        if (props.disabled) return immersionMap(savedImmersion);

        if (selectedImmersion) return immersionMap(selectedImmersion);
        else if (savedImmersion) return immersionMap(savedImmersion);
        // in the case of inactive immersions, at least show the name
        // since they may be in the list of past sessions and can be viewed
        else if (props.savedImmersionName)
            return immersionMap({
                name: props.savedImmersionName,
                topic: {
                    id: "",
                    name: props.savedImmersionTopic || "",
                },
                tactic: props.savedImmersionTactic || "",
            });

        return "Select a module";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.disabled, selectedImmersion, savedImmersion]);

    const isImmersionBuilt = (id: string) => {
        return (
            immersions?.find((immersion) => immersion.id === id)
                ?.buildStatus === "Built"
        );
    };

    return (
        <Flex>
            <Downshift
                initialInputValue={
                    selectedImmersion ? immersionMap(selectedImmersion) : ""
                }
                initialSelectedItem={selectedImmersion}
                itemToString={immersionMap}
                onChange={props.onChange}
            >
                {({
                    clearSelection,
                    getInputProps,
                    getItemProps,
                    highlightedIndex,
                    inputValue,
                    isOpen,
                    openMenu,
                    selectedItem,
                    reset,
                    getRootProps,
                }) => {
                    if (
                        selectedItem &&
                        selectedItem.id === props.savedImmersionId
                    ) {
                        clearSelection();
                    }

                    const organizationItems: ImmersionGroup[] = groupImmersions(
                        { input: inputValue, custom: true },
                    );
                    const allItems: ImmersionGroup[] = groupImmersions({
                        input: inputValue,
                        custom: false,
                        offset: organizationItems.flatMap((_) => _.items)
                            .length,
                    });

                    const sections = [
                        {
                            name: isSuperAdmin
                                ? "Custom Modules"
                                : organizationName + " Modules",
                            items: organizationItems,
                            show: organizationItems.length !== 0,
                        },
                        {
                            name: "Sparkwise Modules",
                            items: allItems,
                            show: allItems.length !== 0,
                        },
                    ];

                    const getItemBg = (
                        index: number,
                        highlightedIndex: number | null,
                        immersionId: string,
                        selectedItemId?: string,
                    ) => {
                        const isSelected = selectedItemId === immersionId;
                        const isHighlighted = highlightedIndex === index;
                        if (isSelected) {
                            return "blue.50";
                        } else if (isHighlighted) {
                            return "gray.50";
                        }
                        return "white";
                    };

                    return (
                        <Flex
                            {...getRootProps()}
                            direction={"row"}
                            alignItems={"flex-end"}
                            w={"100%"}
                        >
                            <Box w={"100%"}>
                                <InputGroup>
                                    <Input
                                        {...getInputProps()}
                                        bg={
                                            props.pending ? "gray.100" : "white"
                                        }
                                        _focus={{
                                            outline: 1,
                                            outlineColor: "blue.500",
                                        }}
                                        borderRadius={"md"}
                                        fontSize={"sm"}
                                        fontWeight={"medium"}
                                        borderColor={"gray.500"}
                                        size={"sm"}
                                        id="immersion"
                                        type="text"
                                        w={"100%"}
                                        h={"36px"}
                                        onFocus={() => openMenu()}
                                        placeholder={placeholder}
                                        disabled={props.disabled}
                                    />
                                    {selectedItem &&
                                    props.showClear === "inline" ? (
                                        <InputRightElement width={"2.5rem"}>
                                            <Box
                                                onClick={() => clearSelection()}
                                                cursor={"pointer"}
                                                mt={-1}
                                            >
                                                <XCircleIcon className="h-6 w-6" />
                                            </Box>
                                        </InputRightElement>
                                    ) : null}
                                </InputGroup>
                                {props.helpText && (
                                    <Text
                                        color="gray.500"
                                        fontSize={"xs"}
                                        mt={2}
                                        mx={2}
                                    >
                                        {props.helpText}
                                    </Text>
                                )}
                                {isOpen && !props.disabled && (
                                    <UnorderedList
                                        position={"absolute"}
                                        maxH={96}
                                        overflow={"auto"}
                                        zIndex={50}
                                        fontSize={"sm"}
                                        fontWeight={"medium"}
                                        borderRadius={"md"}
                                        bg={"white"}
                                        border={"1px solid"}
                                        borderColor={"gray.100"}
                                        mr={2}
                                        ml={0}
                                        mt={"12px"}
                                        minHeight={"120px"}
                                        minW={"650px"}
                                    >
                                        {sections.map(
                                            (section, sectionIndex) => {
                                                if (!section.show) {
                                                    return null;
                                                }
                                                return (
                                                    <UnorderedList
                                                        pb={3}
                                                        pt={3}
                                                        ml={0}
                                                        key={section.name}
                                                        borderTopWidth={
                                                            sectionIndex > 0
                                                                ? 1
                                                                : 0
                                                        }
                                                        borderColor={"gray.100"}
                                                    >
                                                        <Heading
                                                            as={"h3"}
                                                            p={3}
                                                            fontSize={"sm"}
                                                            textColor={
                                                                "gray.800"
                                                            }
                                                            fontWeight={"bold"}
                                                        >
                                                            {section.name}
                                                        </Heading>
                                                        <UnorderedList
                                                            styleType={"none"}
                                                            ml={0}
                                                        >
                                                            {section.items.map(
                                                                ({
                                                                    topic,
                                                                    items,
                                                                }) => (
                                                                    <ListItem
                                                                        key={
                                                                            topic
                                                                        }
                                                                    >
                                                                        <Text
                                                                            textColor={
                                                                                "dark.800"
                                                                            }
                                                                            p={
                                                                                3
                                                                            }
                                                                            fontSize={
                                                                                "sm"
                                                                            }
                                                                        >
                                                                            {
                                                                                topic
                                                                            }
                                                                        </Text>
                                                                        <UnorderedList
                                                                            styleType={
                                                                                "none"
                                                                            }
                                                                            ml={
                                                                                0
                                                                            }
                                                                            pb={
                                                                                4
                                                                            }
                                                                        >
                                                                            {items.map(
                                                                                ({
                                                                                    immersion,
                                                                                    index,
                                                                                }) => (
                                                                                    <ListItem
                                                                                        key={`${immersion.id}${index}`}
                                                                                        {...getItemProps(
                                                                                            {
                                                                                                item: immersion,
                                                                                                index,
                                                                                            },
                                                                                        )}
                                                                                        ml={
                                                                                            0
                                                                                        }
                                                                                        bg={getItemBg(
                                                                                            index,
                                                                                            highlightedIndex,
                                                                                            immersion.id,
                                                                                            selectedItem?.id,
                                                                                        )}
                                                                                        textColor={
                                                                                            "dark.800"
                                                                                        }
                                                                                        fontWeight={
                                                                                            "light"
                                                                                        }
                                                                                        cursor={
                                                                                            "pointer"
                                                                                        }
                                                                                        py={
                                                                                            2
                                                                                        }
                                                                                        pl={
                                                                                            4
                                                                                        }
                                                                                        fontSize={
                                                                                            "sm"
                                                                                        }
                                                                                    >
                                                                                        <ListItemDetail
                                                                                            label={immersionMap(
                                                                                                immersion,
                                                                                            )}
                                                                                            isBuilt={isImmersionBuilt(
                                                                                                immersion.id,
                                                                                            )}
                                                                                            isSelected={
                                                                                                selectedItem?.id ===
                                                                                                immersion.id
                                                                                            }
                                                                                        />
                                                                                    </ListItem>
                                                                                ),
                                                                            )}
                                                                        </UnorderedList>
                                                                    </ListItem>
                                                                ),
                                                            )}
                                                        </UnorderedList>
                                                    </UnorderedList>
                                                );
                                            },
                                        )}
                                    </UnorderedList>
                                )}
                            </Box>
                            {props.showSave || props.showClear === true ? (
                                <Box mx={2} />
                            ) : null}
                            <Flex gap={2}>
                                {props.showSave && (
                                    <Button
                                        isDisabled={props.saveDisabled}
                                        onClick={() => props.onSave?.()}
                                    >
                                        {props.saveLabel || ""}
                                    </Button>
                                )}
                                {props.showClear &&
                                    props.showClear !== "inline" && (
                                        <Button
                                            isDisabled={!selectedImmersion}
                                            onClick={() => {
                                                clearSelection();
                                                props.onChange(null);
                                            }}
                                            colorScheme={"gray"}
                                        >
                                            Reset
                                        </Button>
                                    )}
                            </Flex>
                        </Flex>
                    );
                }}
            </Downshift>
        </Flex>
    );
};

const ListItemDetail: React.FC<{
    isBuilt?: boolean;
    isSelected?: boolean;
    label: string;
}> = ({ label, isBuilt, isSelected }) => {
    return (
        <HStack>
            <Text m={0} p={0}>
                {isSelected ? (
                    <Icon
                        as={CheckIcon}
                        w={"1em"}
                        h={"1em"}
                        transform={"scale(1.2)"}
                    />
                ) : null}{" "}
                {label}
            </Text>
            {!isBuilt && (
                <Text
                    as={"span"}
                    ml={2}
                    textColor={"white"}
                    borderRadius={"md"}
                    px={1.5}
                    py={1}
                    bg={"yellow.400"}
                >
                    unbuilt
                </Text>
            )}
        </HStack>
    );
};

export default ImmersionSelect;
