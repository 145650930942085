import { Box, Icon } from "@chakra-ui/react";
import { ReactComponent as Notepad } from "design/assets/Notepad.svg";
import { ArtifactCard, ArtifactCardProps } from "./ArtifactCard";
import { InsightsArtifactMetadata } from "./InsightsArtifactMetadata";
import { InsightsArtifact, InsightsEtherpadArtifact } from "./InsightsContext";

export const NotepadCard: React.FC<
    ArtifactCardProps & {
        artifact: InsightsArtifact;
        etherpad: InsightsEtherpadArtifact;
    }
> = (props) => {
    const { artifact, etherpad } = props;
    const metadata = {
        immersionName: artifact.immersion.name,
        immersionId: artifact.immersion.id,
        participants: etherpad.participants || [],
        steps: etherpad.steps || [],
        date: new Date(artifact.time),
        timeslotId: artifact.timeslotId,
        cohort: artifact.cohort || undefined,
    };

    return (
        <ArtifactCard
            title={"Notepad"}
            subtitle={"Practice personifying"}
            icon={<Icon as={Notepad} mb={1} mr={1} />}
            groupType={etherpad.participationType}
            metadata={<InsightsArtifactMetadata {...metadata} />}
            showMetadata={props.showMetadata}
            expanded={props.expanded}
        >
            <Box paddingX="40px" paddingY="24px">
                <div
                    className="notepad-content"
                    dangerouslySetInnerHTML={{
                        __html: etherpad.content || "",
                    }}
                ></div>
            </Box>
        </ArtifactCard>
    );
};
