import { useNavigate } from "react-router-dom";
import Button from "../../components/common/Button";
import { trpc } from "../../hooks/useTRPC";
import { useNeedsSidebarCollapsed } from "../../context/SidebarContext";

export const NewEtherpad = () => {
    useNeedsSidebarCollapsed();
    const { mutateAsync: createPad } =
        trpc.learningDesign.createPad.useMutation();
    const navigate = useNavigate();
    return (
        <Button
            label="Generate New Etherpad"
            onClick={async () => {
                const padId = await createPad();
                navigate(`/etherpad/${padId}`);
            }}
        ></Button>
    );
};
