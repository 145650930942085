import {
    Box,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    ListItem,
    UnorderedList,
} from "@chakra-ui/react";
import {
    ChevronDownIcon,
    ChevronUpIcon,
    XCircleIcon,
} from "@heroicons/react/24/outline";
import Downshift from "downshift";
import React from "react";

const DEFAULT_WIDTH = 400;

interface InsightsSelectProps<T> {
    items: T[];
    itemToString: (item: T | null) => string;
    mapItemKey: (item: T) => string;
    leftHandIcon?: React.ReactNode;
    placeholder?: string;
    onChange?: (item: T | null) => void;
    initialItem?: T;
    /**
     * If set to true, the input field will be functional and items can be filtered
     */
    searchable?: boolean;
    width?: number;
    /**
     * Allows the select item to be cleared by showing an "x" icon
     */
    clearable?: boolean;
}

export const InsightsSelect = <T,>(props: InsightsSelectProps<T>) => {
    return (
        <Downshift
            itemToString={props.itemToString}
            onChange={props.onChange}
            initialSelectedItem={props.initialItem}
        >
            {({
                getRootProps,
                isOpen,
                openMenu,
                closeMenu,
                inputValue,
                getItemProps,
                getInputProps,
                itemToString,
                selectedItem,
                highlightedIndex,
                clearSelection,
            }) => {
                const showClearIcon = props.clearable && selectedItem;
                return (
                    <Box {...getRootProps()}>
                        <InputGroup>
                            {props.leftHandIcon && (
                                <InputLeftElement pointerEvents={"none"} mt={0.5} ml={0.5}>
                                    {props.leftHandIcon}
                                </InputLeftElement>
                            )}
                            {props.searchable ? (
                                <Input
                                    {...getInputProps()}
                                    placeholder={props.placeholder}
                                    onFocus={() => openMenu()}
                                    bg={"white"}
                                    width={props.width}
                                    height={'44px'}
                                />
                            ) : (
                                <Input
                                    readOnly
                                    cursor={"pointer"}
                                    value={itemToString(selectedItem)}
                                    onFocus={() => openMenu()}
                                    onClick={() => openMenu()}
                                    bg={"white"}
                                    width={props.width}
                                    height={'44px'}
                                />
                            )}
                            <InputRightElement
                                cursor={"pointer"}
                                onClick={() => {
                                    if (showClearIcon) {
                                        return clearSelection();
                                    }
                                    if (isOpen) {
                                        closeMenu();
                                    } else {
                                        openMenu();
                                    }
                                }}
                            >
                                {!isOpen && showClearIcon && (
                                    <XCircleIcon className="h-6 w-6" />
                                )}
                                {!isOpen && !showClearIcon && (
                                    <ChevronDownIcon height={"18px"} />
                                )}
                                {isOpen && <ChevronUpIcon height={"18px"} />}
                            </InputRightElement>
                        </InputGroup>
                        {isOpen && (
                            <Box
                                position={"absolute"}
                                zIndex={100}
                                mt="6px"
                                p={0}
                                bg="white"
                                borderRadius={"8px"}
                                borderColor={"gray.200"}
                                borderStyle={"solid"}
                                borderWidth={"1px"}
                                display={"block"}
                                maxHeight={"200px"}
                                minHeight={"60px"}
                                overflow={"scroll"}
                                width={props.width || DEFAULT_WIDTH}
                            >
                                <UnorderedList
                                    overflow={"scroll"}
                                    styleType={"none"}
                                    paddingY={"4px"}
                                    paddingX={0}
                                    m={0}
                                >
                                    {props.items
                                        .filter((_) => {
                                            if (!props.searchable) {
                                                return true;
                                            }
                                            return itemToString(_)
                                                .toLowerCase()
                                                .includes(
                                                    inputValue?.toLowerCase() ||
                                                        "",
                                                );
                                        })
                                        .map((item, index) => (
                                            <ListItem
                                                {...getItemProps({
                                                    item,
                                                })}
                                                key={props.mapItemKey(item)}
                                                cursor={"pointer"}
                                                paddingX={"16px"}
                                                paddingY={"12px"}
                                                bg={
                                                    highlightedIndex === index
                                                        ? "gray.50"
                                                        : "white"
                                                }
                                            >
                                                {itemToString(item)}
                                            </ListItem>
                                        ))}
                                </UnorderedList>
                            </Box>
                        )}
                    </Box>
                );
            }}
        </Downshift>
    );
};
