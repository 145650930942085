import { Flex } from "@chakra-ui/react";
import classNames from "classnames";
import { useEffect } from "react";
import Loading from "../components/common/Loading";
import { useGlobalContext } from "../context";
import { useTRPC } from "../hooks/useTRPC";
import { ParamsProps, withParams } from "../utils";
import { useNeedsSidebarCollapsed } from "../context/SidebarContext";

const Impact = (props: ParamsProps) => {
    useNeedsSidebarCollapsed();
    const context = useGlobalContext();

    const trpc = useTRPC();
    const { data: org, error } = trpc.org.getOrgById.useQuery(
        props.params.organizationId || "",
    );
    const { data: iframeUrl } =
        trpc.impact.getImpactDashboardByOrganizationId.useQuery({
            orgId: props.params.organizationId || "",
        });

    useEffect(() => {
        if (org) {
            context.setOrganizationId(org.id);
        }
    }, [context, org]);

    if (!org) {
        return <Loading error={error?.message} />;
    }

    if (!iframeUrl) {
        return <div>(None)</div>;
    }

    return (
        <Flex w="100%" h="100%" direction="column" overflow="hidden">
            <iframe
                title="Impact Dashboard"
                src={iframeUrl.iframeUrl}
                className={classNames(
                    "flex",
                    "flex-1",
                    "w-full",
                    "h-full",
                    "overflow-hidden",
                )}
            />
        </Flex>
    );
};

export default withParams(Impact);
