import {
    Box,
    Button,
    Flex,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Tooltip,
    useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { AdminUserCreateForm } from "./AdminUserCreateForm";
import { AdminUserItem } from "./AdminUserItem";
import { AdminUser, adminUserToString } from "./utils";
import { ReactComponent as EraserIcon } from "design/assets/Eraser.svg";
import { trpc } from "../../../hooks/useTRPC";
import Loading from "../../../components/common/Loading";
import Table from "../../../components/common/Table";
import Toggle from "../../../components/common/Toggle";

export const OrgPermissions: React.FC<{ organizationId: string }> = (
    params,
) => {
    const organizationId: string = params.organizationId;

    // Deletion modal
    const [showModal, setShowModal] = useState<boolean>(false);
    const [adminToDelete, setAdminToDelete] = useState<AdminUser | null>(null);
    const closeModal = () => setShowModal(false);

    const toast = useToast();

    const {
        data: allUsers,
        refetch: refetchAllUsers,
        isLoading,
    } = trpc.org.getOrgUsers.useQuery({
        organizationId: organizationId,
    });
    const { mutateAsync: setPermission } =
        trpc.org.setOrgClientAdminPermission.useMutation();
    const { mutateAsync: setUserAdmin } = trpc.user.setUserAdmin.useMutation({
        onSuccess: (_, variables) => {
            refetchAllUsers();
        },
    });
    const utils = trpc.useUtils();

    const nonAdmins = allUsers?.filter((_) => !_.isClientAdmin) || [];
    const admins = allUsers?.filter((_) => _.isClientAdmin) || [];

    const setToggle = async (
        userId: string,
        permission: keyof AdminUser["permissions"],
        value: boolean,
    ) => {
        const prevData = utils.org.getOrgUsers.getData({
            organizationId: organizationId,
        });
        const existingUser = prevData?.find((_) => _.user.id === userId);
        if (!existingUser) {
            return;
        }
        const updatedUser = {
            ...existingUser,
            permissions: {
                ...existingUser.permissions,
                [permission]: {
                    ...existingUser.permissions[permission],
                    value,
                },
            },
        };
        utils.org.getOrgUsers.setData(
            {
                organizationId,
            },
            (oldData) =>
                oldData?.map((item) => {
                    if (item.user.id === userId) {
                        return updatedUser;
                    }
                    return item;
                }),
        );
        const permissionName = (() => {
            switch (permission) {
                case "sessions":
                    return "sessionsPermission";
                case "analytics":
                    return "impactPermission";
                case "cohorts":
                    return "cohortsPermission";
                case "communications":
                    return "communicationPermission";
                case "users":
                    return "usersPermission";
                case "insights":
                    return "insightsPermission";
            }
        })();
        await setPermission({
            organizationId,
            userId,
            permission: permissionName,
            value,
        });
    };

    const addAdmin = async (userId: string) => {
        const prevData = utils.org.getOrgUsers.getData({
            organizationId: organizationId,
        });
        const existingUser = prevData?.find((_) => _.user.id === userId);
        if (!existingUser) {
            return;
        }
        const updatedUser = {
            ...existingUser,
            isClientAdmin: true,
        };
        utils.org.getOrgUsers.setData(
            {
                organizationId: organizationId,
            },
            (oldData) =>
                oldData?.map((item) => {
                    if (item.user.id === userId) {
                        return updatedUser;
                    }
                    return item;
                }),
        );
        await setUserAdmin({
            userId,
            isAdmin: true,
        });
        toast({
            duration: 5000,
            position: "top",
            render: () => (
                <Box
                    width="max-content"
                    background="gray.700"
                    position="relative"
                    borderRadius="4px"
                    color="white"
                    p={4}
                >
                    <Text fontSize={"sm"}>
                        <b>{adminUserToString(existingUser)}</b> has been
                        successfully added as an admin
                    </Text>
                </Box>
            ),
        });
    };

    const removeAdmin = async () => {
        if (!adminToDelete) {
            return;
        }
        setAdminToDelete(null);
        setShowModal(false);
        utils.org.getOrgUsers.setData(
            {
                organizationId: organizationId,
            },
            (oldData) =>
                oldData?.map((item) => {
                    if (item.user.id === adminToDelete.user.id) {
                        return {
                            ...item,
                            isClientAdmin: false,
                        };
                    }
                    return item;
                }),
        );
        await setUserAdmin({
            userId: adminToDelete.user.id,
            isAdmin: false,
        });
        toast({
            duration: 5000,
            position: "top",
            render: () => (
                <Box
                    width="max-content"
                    background="gray.700"
                    position="relative"
                    borderRadius="4px"
                    color="white"
                    p={4}
                >
                    <Text fontSize={"sm"}>
                        <b>{adminUserToString(adminToDelete)}</b> has been
                        successfully removed as an admin
                    </Text>
                </Box>
            ),
        });
    };

    const columns: [string, string][] = [
        ["user", "Admin"],
        ["users_permission", "Users"],
        ["sessions_permission", "Sessions"],
        ["cohorts_permission", "Cohorts"],
        ["communications_permission", "Communications"],
        ["analytics_permission", "Analytics"],
        ["insights_permission", "Insights"],
    ];

    const page = admins?.map(({ user, permissions }) => ({
        id: user.id,
        user: {
            value: (
                <AdminUserItem
                    firstName={user.firstName}
                    lastName={user.lastName}
                    email={user.emails[0].email}
                    link={`/user/${user.id}`}
                />
            ),
        },
        users_permission: {
            value: (
                <TableToggle
                    item="users"
                    permissions={permissions}
                    onChange={() =>
                        setToggle(user.id, "users", !permissions.users.value)
                    }
                />
            ),
        },
        sessions_permission: {
            value: (
                <TableToggle
                    item="sessions"
                    permissions={permissions}
                    onChange={() =>
                        setToggle(
                            user.id,
                            "sessions",
                            !permissions.sessions.value,
                        )
                    }
                />
            ),
        },
        cohorts_permission: {
            value: (
                <TableToggle
                    item="cohorts"
                    permissions={permissions}
                    onChange={() =>
                        setToggle(
                            user.id,
                            "cohorts",
                            !permissions.cohorts.value,
                        )
                    }
                />
            ),
        },
        communications_permission: {
            value: (
                <TableToggle
                    item="communications"
                    permissions={permissions}
                    onChange={() =>
                        setToggle(
                            user.id,
                            "communications",
                            !permissions.communications.value,
                        )
                    }
                />
            ),
        },
        analytics_permission: {
            value: (
                <TableToggle
                    item="analytics"
                    permissions={permissions}
                    onChange={() =>
                        setToggle(
                            user.id,
                            "analytics",
                            !permissions.analytics.value,
                        )
                    }
                />
            ),
        },
        insights_permission: {
            value: (
                <TableToggle
                    item="insights"
                    permissions={permissions}
                    onChange={() =>
                        setToggle(
                            user.id,
                            "insights",
                            !permissions.insights.value,
                        )
                    }
                />
            ),
        },
    }));

    const buildContextualElement = (row: { id: string }) => (
        <Flex alignItems={"center"} justifyContent={"center"} h={"100%"} ml={5}>
            <Button
                onClick={() => {
                    if (admins.find((_) => _.user.id === row.id)) {
                        setAdminToDelete(
                            admins.find((_) => _.user.id === row.id) || null,
                        );
                        setShowModal(true);
                    }
                }}
                colorScheme="gray"
                variant="outline"
                p={0}
                _hover={{
                    color: "danger.0",
                    backgroundColor: "danger.-5",
                    borderColor: "danger.-4",
                }}
            >
                <Icon as={EraserIcon} />
            </Button>
        </Flex>
    );

    const hasData = page.length > 0;

    return (
        <div className="w-fit">
            <AdminUserCreateForm users={nonAdmins} onAddUser={addAdmin} />
            <hr className="mt-2" />
            {isLoading ? <Loading /> : null}
            {hasData ? (
                <Table
                    columns={columns}
                    data={{
                        "0": page || [],
                    }}
                    page={0}
                    onPageChange={() => {}}
                    sortable={false}
                    hidePages={true}
                    contextualActionElement={buildContextualElement}
                />
            ) : null}
            {!hasData && !isLoading ? <EmptyState /> : null}
            <Modal isOpen={showModal} onClose={() => closeModal()}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Removing admin role</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        This will only remove user’s admin role. You can always
                        add them again.
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => closeModal()}>Cancel</Button>
                        <Button
                            ml={5}
                            colorScheme="red"
                            onClick={() => removeAdmin()}
                        >
                            Remove
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

const EmptyState = () => (
    <Box width={650}>
        <Flex justifyContent={"center"}>
            <Text align={"center"} mt={10} mb={10} color={"gray.500"}>
                No Admin users yet
            </Text>
        </Flex>
    </Box>
);

const TableToggle: React.FC<{
    permissions: AdminUser["permissions"];
    item: keyof AdminUser["permissions"];
    onChange: () => void;
}> = ({ permissions, item, onChange }) => {
    return (
        <Tooltip
            hasArrow
            placement={"bottom"}
            label={
                !permissions[item].enabled
                    ? `This setting can't be changed`
                    : undefined
            }
            bg="black"
            color="white"
            padding={3}
            borderRadius={10}
        >
            <Box w={"44px"}>
                <Toggle
                    value={permissions[item].value}
                    label={""}
                    onChange={onChange}
                    disabled={!permissions[item].enabled}
                />
            </Box>
        </Tooltip>
    );
};
