import {
    Box,
    Checkbox,
    CheckboxGroup,
    Flex,
    Skeleton,
    Stack,
    Text,
} from "@chakra-ui/react";
import {
    BookOpenIcon,
    CalendarDaysIcon,
    RectangleGroupIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useActiveCohorts } from "../../hooks/useActiveCohorts";
import { useInsights } from "./InsightsContext";
import { InsightsSelect } from "./InsightsSelect";
import { useInsightsImmersions } from "../../hooks/useInsightsImmersions";

export const InsightsSidebar: React.FC = () => {
    const {
        state,
        setModuleId,
        showEtherpad,
        showExcalidraw,
        showTranscripts,
        setDateFilter,
        setCohortId,
        loading,
    } = useInsights();
    const immersions = useInsightsImmersions();
    const cohorts = useActiveCohorts();
    const isReady = !!immersions && !!cohorts;
    const days = [
        {
            name: "Last 24 hours",
            id: "1",
        },
        {
            name: "Last 7 days",
            id: "7",
        },
        {
            name: "Last 30 days",
            id: "30",
        },
        {
            name: "Last 365 days",
            id: "365",
        }
    ];
    if (!state.filters.moduleId) {
        return null;
    }
    return (
        <Flex
            bg="white"
            basis={"300px"}
            grow={0}
            shrink={0}
            p={"24px"}
            borderRight={"1px solid"}
            borderColor={"gray.100"}
            direction={"column"}
        >
            <Box mb="48px">
                <Text
                    as="h3"
                    color="black"
                    fontSize={"18px"}
                    fontWeight={600}
                    lineHeight={"25px"}
                    mb={"24px"}
                >
                    Session Filters
                </Text>
                {!state.filters.moduleId && (
                    <Text
                        fontSize={"14px"}
                        fontWeight={400}
                        lineHeight={"17px"}
                        mb="12px"
                    >
                        Please select a module
                    </Text>
                )}
                {isReady ? (
                    <InsightsSelect
                        initialItem={immersions.find(
                            (_) => _.id === state.filters.moduleId,
                        )}
                        searchable
                        itemToString={(_) => {
                            return _?.tactic || "";
                        }}
                        mapItemKey={(_) => _.id}
                        items={immersions || []}
                        placeholder="Module"
                        onChange={(_) => {
                            if (_) {
                                setModuleId(_.id);
                            }
                        }}
                        leftHandIcon={<BookOpenIcon height={"18px"} />}
                    />
                ) : (
                    <Skeleton height="40px" />
                )}
                <Box mt="12px" />
                <Skeleton isLoaded={!!isReady}>
                    <InsightsSelect
                        initialItem={days.find(
                            (_) => _.id === state.filters.date.toString(),
                        )}
                        itemToString={(_) => _?.name || ""}
                        mapItemKey={(_) => _.id}
                        items={days}
                        onChange={(value) => {
                            if (value && !isNaN(parseInt(value.id))) {
                                setDateFilter(parseInt(value.id));
                            }
                        }}
                        leftHandIcon={<CalendarDaysIcon height={"18px"} />}
                    />
                </Skeleton>
                <Box mt="12px" />
                <Skeleton isLoaded={!!isReady}>
                    <InsightsSelect
                        searchable
                        clearable
                        itemToString={(_) => _?.name || ""}
                        mapItemKey={(_) => _.id}
                        placeholder="All cohorts"
                        items={cohorts || []}
                        onChange={(value) => {
                            if (value) {
                                setCohortId(value.id);
                            } else {
                                setCohortId(undefined);
                            }
                        }}
                        leftHandIcon={<RectangleGroupIcon height={"18px"} />}
                    />
                </Skeleton>
            </Box>
            <Box>
                <Text
                    as="h3"
                    color="black"
                    fontSize={"18px"}
                    fontWeight={600}
                    lineHeight={"25px"}
                    mb={"24px"}
                >
                    Resource Types
                </Text>
                <CheckboxGroup
                    onChange={(values) => {
                        showEtherpad(values.includes("etherpad"));
                        showTranscripts(values.includes("transcript"));
                        showExcalidraw(values.includes("excalidraw"));
                    }}
                    defaultValue={[
                        state.filters.showEtherpad ? "etherpad" : "",
                        state.filters.showTranscripts ? "transcript" : "",
                        state.filters.showExcalidraw ? "excalidraw" : "",
                    ]}
                >
                    <Stack direction={"column"} spacing="20px" ml="12px">
                        <Checkbox value="transcript">
                            <Skeleton isLoaded={!loading}>
                                Conversations{" "}
                                <Text as="span" color="gray.300">
                                    ({state.transcriptCount})
                                </Text>
                            </Skeleton>
                        </Checkbox>
                        <Checkbox value="etherpad">
                            <Skeleton isLoaded={!loading}>
                                Notepads{" "}
                                <Text as="span" color="gray.300">
                                    ({state.etherpadCount})
                                </Text>
                            </Skeleton>
                        </Checkbox>
                        <Checkbox value="excalidraw">
                            <Skeleton isLoaded={!loading}>
                                Whiteboards{" "}
                                <Text as="span" color="gray.300">
                                    ({state.excalidrawCount})
                                </Text>
                            </Skeleton>
                        </Checkbox>
                    </Stack>
                </CheckboxGroup>
            </Box>
        </Flex>
    );
};
